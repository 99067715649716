import { useMachine } from '@xstate/react';
import React, { ReactNode } from 'react';

import checkoutMachine, {
  CheckoutContext,
  PayWithNewCardEvent,
} from '../machines/checkout';
import createTypeSafeContext from '../utils/createTypeSafeContext';
import { useAuth } from './auth';
import { useBasket } from './basket';

export interface Checkout {
  addNewPaymentMethod: () => void;
  canPay: boolean;
  canRemoveBasketItems: boolean;
  errorMessage: CheckoutContext['errorMessage'];
  isAddingNewCard: boolean;
  isAwaitingPayment: boolean;
  isPaying: boolean;
  isSigningIn: boolean;
  isStarted: boolean;
  payWithNewCard: (options: Omit<PayWithNewCardEvent, 'type'>) => void;
  payWithSelectedCard: () => void;
  paymentMethods: CheckoutContext['paymentMethods'];
  reset: () => void;
  selectedPaymentMethodId: CheckoutContext['selectedPaymentMethodId'];
  setPaymentMethod: (paymentMethodId: string) => void;
  start: () => void;
}

const [useCheckout, CheckoutProvider] = createTypeSafeContext<Checkout>();
export { useCheckout };

function useProvideCheckout(): Checkout {
  const basket = useBasket();
  const { user } = useAuth();
  const [current, send, service] = useMachine(checkoutMachine, {
    context: {
      isAddingNewCard: false,
      onOrderComplete: () => {
        basket.reset();
      },
      user,
    },
  });

  React.useEffect(() => {
    if (user && !service.state.context.user) {
      send({ type: 'SIGNED_IN', user });
    }
  }, [service, send, user]);

  React.useEffect(() => {
    let isUnmounted = false;
    if (!basket.paymentRequest) {
      return () => {
        isUnmounted = true;
      };
    }
    basket.paymentRequest.on('paymentmethod', (event) => {
      if (isUnmounted) {
        return;
      }

      if (!basket.paymentIntent) {
        throw new Error('Missing payment intent');
      }

      send({
        paymentIntent: basket.paymentIntent,
        paymentMethodEvent: event,
        paymentMethodId: event.paymentMethod.id,
        type: 'PAY_WITH_PAYMENT_REQUEST',
      });
    });
    return () => {
      isUnmounted = true;
    };
  }, [basket.paymentIntent, basket.paymentRequest, send]);

  const isPaying = current.matches({ pay: 'paying' });

  return {
    addNewPaymentMethod: () => send({ type: 'ADD_NEW_PAYMENT_METHOD' }),
    canPay:
      current.matches({ pay: 'fetchingPaymentMethods' }) ||
      current.matches({ pay: 'awaitingPayment' }),
    canRemoveBasketItems:
      basket.canModify && !isPaying && !current.matches('checkedOut'),
    errorMessage: current.context.errorMessage,
    isAddingNewCard: current.context.isAddingNewCard,
    isAwaitingPayment: current.matches({ pay: 'awaitingPayment' }),
    isPaying,
    isSigningIn: current.matches('signIn'),
    isStarted: !current.matches('idle'),
    payWithNewCard: (options) =>
      send({ ...options, type: 'PAY_WITH_NEW_CARD' }),
    payWithSelectedCard: () => {
      if (!basket.paymentIntent) {
        throw new Error('Missing payment intent');
      }
      send({
        paymentIntent: basket.paymentIntent,
        type: 'PAY_WITH_SELECTED_CARD',
      });
    },
    paymentMethods: current.context.paymentMethods,
    reset: () => send({ type: 'RESET' }),
    selectedPaymentMethodId: current.context.selectedPaymentMethodId,
    setPaymentMethod: (paymentMethodId) =>
      send({
        paymentMethodId,
        type: 'SET_PAYMENT_METHOD',
      }),
    start: () => send({ type: 'START' }),
  };
}

export const ProvideCheckout = ({ children }: { children?: ReactNode }) => {
  const checkout = useProvideCheckout();
  return <CheckoutProvider value={checkout}>{children}</CheckoutProvider>;
};
