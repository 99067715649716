import { ApolloQueryResult } from '@apollo/client';
import {
  Stripe,
  StripeCardElement,
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js';
import Router from 'next/router';
import toast from 'react-hot-toast';
import { Machine, assign, DoneInvokeEvent } from 'xstate';

import { pathForResource } from '@chartsy/shared/utils';

import { refreshOrderStatus } from '../api';
import apolloClient from '../apolloClient';
import { ShopUser } from '../contexts/auth';
import {
  BasketPaymentMethodFragment,
  PaymentMethodsQuery,
  PaymentMethodsDocument,
  CreatePaymentIntentResponse,
} from '../generatedGraphql';
import stripePromise from '../utils/stripePromise';

type PaymentMethodsResult = ApolloQueryResult<PaymentMethodsQuery>;

type UnfinishedPaymentMethodsResult = {
  value: Promise<PaymentMethodsResult>;
  didFinish: false;
};

type FinishedPaymentMethodsResult = {
  value: PaymentMethodsResult;
  didFinish: true;
};

type FetchingPaymentMethodsInitialResult =
  | FinishedPaymentMethodsResult
  | UnfinishedPaymentMethodsResult;

type FetchingPaymentMethodsResult =
  | { value: PaymentMethodsResult; didFinish: true }
  | UnfinishedPaymentMethodsResult;

export interface CheckoutContext {
  errorMessage?: string;
  isAddingNewCard: boolean;
  onOrderComplete: ({ orderId }: { orderId: string }) => void;
  paymentMethods?: BasketPaymentMethodFragment[];
  paymentMethodsPromise?: Promise<PaymentMethodsResult>;
  selectedPaymentMethodId?: string;
  user: ShopUser;
}

interface CheckoutSchema {
  states: {
    checkForUser: Record<string, unknown>;
    signIn: Record<string, unknown>;
    idle: Record<string, unknown>;
    pay: {
      states: {
        checkForPaymentMethods: Record<string, unknown>;
        fetchingPaymentMethods: Record<string, unknown>;
        awaitingPayment: Record<string, unknown>;
        paying: Record<string, unknown>;
      };
    };
    reset: Record<string, unknown>;
  };
}

interface SignedInEvent {
  type: 'SIGNED_IN';
  user: ShopUser;
}

interface ResetEvent {
  type: 'RESET';
}

interface StartEvent {
  type: 'START';
}

interface SetPaymentMethodEvent {
  paymentMethodId: string;
  type: 'SET_PAYMENT_METHOD';
}

interface AddNewPaymentMethodEvent {
  type: 'ADD_NEW_PAYMENT_METHOD';
}

interface BasePayEvent {
  paymentIntent: CreatePaymentIntentResponse;
}

interface PayWithSelectedCardEvent extends BasePayEvent {
  type: 'PAY_WITH_SELECTED_CARD';
}

export interface PayWithNewCardEvent extends BasePayEvent {
  cardElement: StripeCardElement;
  cardholderName: string;
  shouldSaveCard: boolean;
  type: 'PAY_WITH_NEW_CARD';
}

export interface PayWithPaymentRequestEvent extends BasePayEvent {
  paymentMethodId: string;
  paymentMethodEvent: PaymentRequestPaymentMethodEvent;
  type: 'PAY_WITH_PAYMENT_REQUEST';
}

type PayEvents =
  | PayWithSelectedCardEvent
  | PayWithNewCardEvent
  | PayWithPaymentRequestEvent;

type CheckoutEvents =
  | SignedInEvent
  | StartEvent
  | ResetEvent
  | AddNewPaymentMethodEvent
  | SetPaymentMethodEvent
  | PayEvents;

function paymentMethodsResultToContext(
  { isAddingNewCard, selectedPaymentMethodId }: CheckoutContext,
  { data }: PaymentMethodsResult,
): Partial<CheckoutContext> {
  return {
    isAddingNewCard: isAddingNewCard || !data?.paymentMethods[0]?.id,
    paymentMethods: data?.paymentMethods,
    selectedPaymentMethodId: isAddingNewCard
      ? undefined
      : selectedPaymentMethodId || data?.paymentMethods[0]?.id,
  };
}

const checkoutMachine = Machine<
  CheckoutContext,
  CheckoutSchema,
  CheckoutEvents
>({
  id: 'checkout',
  initial: 'checkForUser',
  states: {
    checkForUser: {
      always: [
        {
          cond: ({ user }) => !!user,
          target: 'idle',
        },
        {
          cond: ({ user }) => !user,
          target: 'signIn',
        },
      ],
    },
    signIn: {
      on: {
        SIGNED_IN: {
          target: 'checkForUser',
          actions: assign<CheckoutContext, SignedInEvent>({
            user: (_context, event) => event.user,
          }),
        },
      },
    },
    idle: {
      on: {
        START: 'pay',
      },
    },
    pay: {
      initial: 'checkForPaymentMethods',
      on: {
        RESET: {
          target: 'checkForUser',
          // Workaround for https://github.com/davidkpiano/xstate/issues/1198
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions: assign<CheckoutContext, any>({
            errorMessage: undefined,
            isAddingNewCard: false,
          }),
        },
      },
      states: {
        checkForPaymentMethods: {
          always: [
            {
              target: 'awaitingPayment',
              cond: ({
                selectedPaymentMethodId,
                paymentMethods,
              }: CheckoutContext) =>
                !!selectedPaymentMethodId &&
                !!paymentMethods &&
                paymentMethods.length > 0,
            },
            { target: 'fetchingPaymentMethods' },
          ],
        },
        fetchingPaymentMethods: {
          on: {
            PAY_WITH_SELECTED_CARD: 'paying',
            ADD_NEW_PAYMENT_METHOD: {
              target: '',
              // Workaround for https://github.com/davidkpiano/xstate/issues/1198
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions: assign<CheckoutContext, any>({
                isAddingNewCard: true,
              }),
            },
          },
          invoke: {
            id: 'fetchPaymentMethods',
            src: async (): Promise<FetchingPaymentMethodsInitialResult> => {
              const paymentMethodsPromise = apolloClient.query({
                fetchPolicy: 'network-only',
                query: PaymentMethodsDocument,
              });
              const wrappedPromise: Promise<FinishedPaymentMethodsResult> = paymentMethodsPromise.then(
                (value) => ({
                  value,
                  didFinish: true,
                }),
              );
              return Promise.race<FetchingPaymentMethodsInitialResult>([
                wrappedPromise,
                new Promise<UnfinishedPaymentMethodsResult>((resolve) =>
                  setTimeout(
                    () =>
                      resolve({
                        value: paymentMethodsPromise,
                        didFinish: false,
                      }),
                    // A hedge against slow loading payment methods
                    2000,
                  ),
                ),
              ]);
            },
            onDone: {
              target: 'awaitingPayment',
              actions: assign<
                CheckoutContext,
                DoneInvokeEvent<FetchingPaymentMethodsResult>
              >((context, { data: result }) => {
                if (!result.didFinish) {
                  return {
                    paymentMethodsPromise: result.value,
                  };
                }
                return paymentMethodsResultToContext(context, result.value);
              }),
            },
            onError: {
              target: 'awaitingPayment',
              // Workaround for https://github.com/davidkpiano/xstate/issues/1198
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions: assign<CheckoutContext, any>({
                errorMessage:
                  'Error retrieving your saved payment methods. You may add a new one or refresh to try loading them again.',
                isAddingNewCard: true,
                paymentMethods: [],
              }),
            },
          },
        },
        awaitingPayment: {
          on: {
            PAY_WITH_SELECTED_CARD: 'paying',
            PAY_WITH_NEW_CARD: 'paying',
            PAY_WITH_PAYMENT_REQUEST: 'paying',
            ADD_NEW_PAYMENT_METHOD: {
              target: '',
              // Workaround for https://github.com/davidkpiano/xstate/issues/1198
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions: assign<CheckoutContext, any>({
                isAddingNewCard: true,
                selectedPaymentMethodId: undefined,
              }),
            },
            SET_PAYMENT_METHOD: {
              target: '',
              actions: assign<CheckoutContext, SetPaymentMethodEvent>({
                isAddingNewCard: false,
                selectedPaymentMethodId: (_context, event) =>
                  event.paymentMethodId,
              }),
            },
          },
          invoke: {
            id: 'waitForPaymentMethods',
            src: async ({ paymentMethodsPromise }: CheckoutContext) =>
              paymentMethodsPromise,
            onDone: {
              target: '',
              actions: assign<
                CheckoutContext,
                DoneInvokeEvent<PaymentMethodsResult>
              >((context, event) => {
                if (!event.data) {
                  return {};
                }
                return paymentMethodsResultToContext(context, event.data);
              }),
            },
          },
        },
        paying: {
          // Workaround for https://github.com/davidkpiano/xstate/issues/1198
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          entry: assign<CheckoutContext, any>({
            errorMessage: undefined,
          }),
          invoke: {
            id: 'paying',
            src: async ({ selectedPaymentMethodId }, event: CheckoutEvents) => {
              if (
                !(
                  event.type === 'PAY_WITH_SELECTED_CARD' ||
                  event.type === 'PAY_WITH_NEW_CARD' ||
                  event.type === 'PAY_WITH_PAYMENT_REQUEST'
                )
              ) {
                throw new Error('Invalid transition');
              }

              const { orderId, clientSecret } = event.paymentIntent;

              const stripe = await stripePromise;

              if (!stripe) {
                throw new Error('Stripe not loaded');
              }

              let confirmPaymentResponse: Unpromise<ReturnType<
                Stripe['confirmCardPayment']
              >>;

              switch (event.type) {
                case 'PAY_WITH_NEW_CARD':
                  confirmPaymentResponse = await stripe.confirmCardPayment(
                    clientSecret,
                    {
                      payment_method: {
                        card: event.cardElement,
                        billing_details: { name: event.cardholderName },
                      },
                      setup_future_usage: event.shouldSaveCard
                        ? 'on_session'
                        : undefined,
                    },
                  );
                  break;
                case 'PAY_WITH_SELECTED_CARD':
                  confirmPaymentResponse = await stripe.confirmCardPayment(
                    clientSecret,
                    {
                      payment_method: selectedPaymentMethodId,
                    },
                  );
                  break;
                case 'PAY_WITH_PAYMENT_REQUEST':
                  confirmPaymentResponse = await stripe.confirmCardPayment(
                    clientSecret,
                    {
                      payment_method: event.paymentMethodId,
                    },
                    { handleActions: false },
                  );
                  if (confirmPaymentResponse.error) {
                    event.paymentMethodEvent.complete('fail');
                  } else {
                    event.paymentMethodEvent.complete('success');
                    if (
                      confirmPaymentResponse.paymentIntent?.status ===
                      'requires_action'
                    ) {
                      const { error } = await stripe.confirmCardPayment(
                        clientSecret,
                      );
                      if (error) {
                        throw new Error(error.message || 'Unknown error');
                      }
                    }
                  }
                  break;
                default:
                  throw new Error('Invalid transition');
              }

              if (confirmPaymentResponse.error) {
                throw new Error(confirmPaymentResponse.error.message);
              }
              // Make sure the order was processed, because Stripe may not have
              // gotten back to use with a webhook yet.
              await refreshOrderStatus({ id: orderId });
              await Router.push(pathForResource('account'));
              toast.success('Order successful!');
              return { orderId };
            },
            onDone: {
              target: '#checkout.reset',
              actions: (
                { onOrderComplete },
                { data: { orderId } }: DoneInvokeEvent<{ orderId: string }>,
              ) => {
                if (!orderId) {
                  throw new Error('Missing order id');
                }
                onOrderComplete({ orderId });
              },
            },
            onError: {
              target: 'awaitingPayment',
              actions: assign<CheckoutContext, DoneInvokeEvent<Error>>(
                (context, { data: error }, meta) => {
                  // Handle unknown error during payment request by dismissing
                  // UI.
                  if (meta.state?.event.type === 'PAY_WITH_PAYMENT_REQUEST') {
                    // Fixing incorrect type for meta manually
                    const event = (meta.state
                      ?.event as unknown) as PayWithPaymentRequestEvent;
                    event.paymentMethodEvent.complete('fail');
                  }
                  return {
                    ...context,
                    errorMessage: error.message,
                  };
                },
              ),
            },
          },
        },
      },
    },
    reset: {
      always: {
        target: 'checkForUser',
        actions: assign<CheckoutContext, CheckoutEvents>({
          errorMessage: undefined,
          isAddingNewCard: false,
        }),
      },
    },
  },
});

export default checkoutMachine;
